import {Spin} from 'antd';
import React from 'react';

interface Props {
  // If set to true, this will place the spinner smackdab in the middle of the
  // screen. This is meant only for root level loading spinners where no other
  // content is on the page yet.
  fullscreen?: boolean;
}

export const Loading = (props: Props) => {
  if (props.fullscreen) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <Spinner />
      </div>
    );
  }

  return <Spinner />;
};

const Spinner = () => (
  <Spin
    data-testid="loading-spinner"
    size="large"
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 0',
    }}
  />
);
